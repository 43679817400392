"use script";
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./com.qcobjects.installer");
require("./org.quickcorp.custom.effects");
require("./org.quickcorp.custom.models");
require("./org.quickcorp.custom.template.handlers");
require("./org.quickcorp.custom.components");
require("./org.quickcorp.custom.components.mardown");
require("./org.quickcorp.custom.controllers.markdown");
require("./org.quickcorp.custom.controllers");
require("./org.quickcorp.custom.views");
require("./com.qcobjects.services.github");
