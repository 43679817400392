/* eslint-disable prefer-rest-params */
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const qcobjects_1 = require("qcobjects");
(0, qcobjects_1.Package)("com.qcobjects.services.github", [
    class GitHubService extends qcobjects_1.JSONService {
        constructor() {
            super(...arguments);
            /**
             * Name of the service
             * @date 18/04/2023 - 22:30:07
             *
             * @type {string}
             */
            this.name = "myservice";
            /**
             * It specifies if the service has a external url. If true, you can pass absolute urls in url property
             * @date 18/04/2023 - 22:30:37
             *
             * @type {boolean}
             */
            this.external = true;
            /**
             * If it is true, the service call will be cached
             * @date 18/04/2023 - 22:31:41
             *
             * @type {boolean}
             */
            this.cached = false;
            /**
             * It can be GET, POST, PUT
             * @date 18/04/2023 - 22:32:29
             *
             * @type {string}
             */
            this.method = "GET";
            /**
             * You can add special headers using this property
             * @date 18/04/2023 - 22:33:20
             *
             * @type {{ "Content-Type": string; }}
             */
            this.headers = { "Content-Type": "application/json" };
            /**
             * URL for the service call
             * @date 18/04/2023 - 22:34:09
             *
             * @type {string}
             */
            this.url = "https://api.github.com/orgs/QuickCorp/repos";
            /**
             * This is used internally for XHR requests. Default value is false.
             * @date 18/04/2023 - 22:34:29
             *
             * @type {boolean}
             */
            this.withCredentials = false;
        }
        /**
         * It is called once the service call is done
         * @param param0 this param has two properties, one is the native request call (XHR or Fetch object). The second property is the service object.
         * @returns Promise
         */
        done({ request, service }) {
            const result = JSON.parse(service.template)
                .reverse().map(function (project) {
                return {
                    id: project.id,
                    description: project.description,
                    title: project.name,
                    url: project.html_url,
                    image: `https://via.placeholder.com/170/000000/FFFFFF?text=${encodeURI(project.name)}`
                };
            });
            service.template = JSON.stringify({
                result
            });
            return super.done({ request, service });
        }
    },
    class GitHubTagService extends qcobjects_1.JSONService {
        constructor() {
            super(...arguments);
            this.name = "myservice";
            this.external = true;
            this.cached = false;
            this.method = "GET";
            this.headers = { "Content-Type": "application/json" };
            this.url = "https://api.github.com/search/repositories?q=qcobjects";
            this.withCredentials = false;
        }
        done({ request, service }) {
            qcobjects_1.logger.debug(request);
            const result = JSON.parse(service.template)
                .items.map(function (project) {
                return {
                    id: project.id,
                    description: project.description,
                    title: project.name,
                    url: project.html_url,
                    image: `https://via.placeholder.com/170/000000/FFFFFF?text=${encodeURI(project.name)}`
                };
            });
            service.template = JSON.stringify({
                result
            });
            return super.done({ request, service });
        }
    },
    class QCObjectsVersionService extends qcobjects_1.Service {
        constructor() {
            super(...arguments);
            this.name = "qcobjects_version_service";
            this.external = true;
            this.cached = false;
            this.method = "GET";
            this.headers = { "Content-Type": "application/json" };
            this.url = "https://api.github.com/repos/QuickCorp/QCObjects/tags";
            this.withCredentials = false;
        }
        done({ service }) {
            const latest = JSON.parse(service.template)[0];
            service.template = {
                version: latest.name
            };
        }
    },
    class QCObjectsStarsForksService extends qcobjects_1.Service {
        constructor() {
            super(...arguments);
            this.name = "qcobjects_stars_forks_service";
            this.external = true;
            this.cached = false;
            this.method = "GET";
            this.headers = { "Content-Type": "application/json" };
            this.url = "https://api.github.com/repos/QuickCorp/QCObjects";
            this.withCredentials = false;
        }
        done({ service }) {
            const repo = JSON.parse(service.template);
            service.template = {
                forks: repo.forks_count,
                stars: repo.stargazers_count,
                watchers: repo.watchers_count,
                size: Math.round(repo.size / 1000)
            };
        }
    }
]);
