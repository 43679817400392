"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const qcobjects_1 = require("qcobjects");
(0, qcobjects_1.Package)("org.quickcorp.custom.controllers.markdown", [
    class MarkdownController extends qcobjects_1.Controller {
        constructor() {
            super(...arguments);
            this.dependencies = [];
        }
        done() {
            if (typeof this.dependencies === "undefined" || this.dependencies === null) {
                this.dependencies = [];
            }
            this.dependencies.push((0, qcobjects_1.New)(qcobjects_1.SourceJS, {
                external: false,
                url: "prism-okaidia.js"
            }));
            this.dependencies.push((0, qcobjects_1.New)(qcobjects_1.SourceCSS, {
                external: false,
                url: "css/prism-okaidia.css"
            }));
        }
    }
]);
