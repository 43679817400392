"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const qcobjects_1 = require("qcobjects");
(0, qcobjects_1.Package)("org.quickcorp.custom.components.markdown", [
    class SideMarkdownComponent extends qcobjects_1.Component {
        constructor() {
            super(...arguments);
            this.dependencies = [];
            this.templateURI = "templates/components/markdown/en/page_en_4.md";
            this.cached = false;
            this.templateHandler = "SideMarkdownTemplateHandler";
        }
        done({ request, component }) {
            return new Promise((resolve) => {
                super.done({ request, component }).then(() => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.shadowRoot.subelements("ul>li>a").map((element) => {
                        return element.addEventListener("click", function () {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            qcobjects_1.global.sideNavController.close();
                        });
                    });
                    resolve({ request, component });
                });
            });
        }
    },
    class MarkdownComponent extends qcobjects_1.Component {
        constructor() {
            super(...arguments);
            this.name = "markdowncomponent";
            this.templateHandler = "MarkdownTemplateHandler";
        }
    }
]);
